import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  private ss = window.localStorage;

  constructor() {}

  public setItem(key: string, value: any) {
    value = JSON.stringify(value);
    this.ss.setItem(key, value);
    return true;
  }

  public getItem(key: string) {
    const value = this.ss.getItem(key);
    try {
      if (!value) {
        this.ss.removeItem(key);
        return null;
      }
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }

  public clear() {
    this.ss.clear();
  }
}
