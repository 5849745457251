import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';

export const API_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>(
  'API_HTTP_INTERCEPTORS'
);

@Injectable({
  providedIn: 'root',
})
export class ApiHttpClient extends HttpClient {
  constructor(
    backend: HttpBackend,
    private readonly injector: Injector
  ) {
    super(new HttpHandlerService(backend, injector, API_HTTP_INTERCEPTORS));
  }
}
