import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenusModel } from '../models/menus.model';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  order?: number;
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor() {}

  public SetPlainText(menu: MenusModel[]) {
    this.plainMenu.length = 0;
    menu.forEach(t => {
      this.plainMenu.push({
        name: t.name,
        type: t.type,
        tooltip: t.tooltip,
        icon: t.icon,
        state: t.state,
        order: t.order,
      });
    });

    this.menuItems.next(structuredClone(this.plainMenu));
  }
  plainMenu: IMenuItem[] = [];
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
}
