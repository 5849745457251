import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { ApiHttpClient } from '../api-http-client.service';
import { LocalStoreService } from '../local-store.service';
import { NavigationService } from '../navigation.service';
import { SessionStoreService } from '../session-store.service';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token: string | null = null;
  isAuthenticated: boolean = false;
  user: User | null = null;
  user$ = new BehaviorSubject<User | null>(this.user);
  signingIn: boolean = false;
  JWT_TOKEN = 'JWT_TOKEN';
  APP_USER = 'EGRET_USER';
  LAST_USER_COMPANY = 'LAST_USER_COMPANY';

  constructor(
    private ss: SessionStoreService,
    private ls: LocalStoreService,
    private http: ApiHttpClient,
    private router: Router,
    private navigationService: NavigationService,
    private readonly translate: TranslateService
  ) {}

  public signin(username: string, password: string) {
    const url = `/api/v1/auth/login`;
    this.signingIn = true;
    return this.http
      .post(url, {
        username,
        password,
      })
      .pipe(
        map((res: any) => {
          this.setUserAndToken(res.token, res.user, res.logged);
          this.signingIn = false;
          if (res.user.language) {
            this.translate.use(res.user.language);
          } else {
            this.translate.use(this.translate.getDefaultLang());
          }
          return res;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  public cambiarContraseña(
    actual: string,
    nueva: string,
    repetirNueva: string
  ) {
    const url = `/api/v1/security/updatePassword`;
    return this.http.post<
      | {
          success: true;
        }
      | {
          success: false;
          mensajes: {
            code: string;
          }[];
        }
    >(url, {
      actual: actual,
      nueva: nueva,
      repetirNueva: repetirNueva,
    });
  }

  public checkTokenIsValid() {
    return this.http.post(`/api/v1/auth/user`, null).pipe(
      map((res: any) => {
        this.setUserAndToken(this.getJwtToken(), res.user, res.logged);
        this.navigationService.SetPlainText(res.user.menu);
        this.signingIn = false;
        if (res.user.language) {
          this.translate.use(res.user.language);
        } else {
          this.translate.use(this.translate.getDefaultLang());
        }
        return res;
      }),
      catchError(error => {
        return of(error);
      })
    );
  }

  public signout() {
    this.setUserAndToken(null, null, false);
    this.translate.use(this.translate.getDefaultLang());
    this.router.navigateByUrl('sessions/signin4');
  }

  isLoggedIn(): boolean {
    return this.getJwtToken();
  }

  getJwtToken() {
    return this.ss.getItem(this.JWT_TOKEN);
  }
  getUser(): User {
    return this.ss.getItem(this.APP_USER);
  }

  setUserAndToken(
    token: string | null,
    user: User | null,
    isAuthenticated: boolean
  ) {
    const lastUserCompany = user ? user.company : this.user?.company;
    this.ls.setItem(this.LAST_USER_COMPANY, lastUserCompany);
    this.user$.next(user);
    this.ss.setItem(this.JWT_TOKEN, token);
    this.ss.setItem(this.APP_USER, user);
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
  }

  getLastUserCompany() {
    return Number(this.ls.getItem(this.LAST_USER_COMPANY));
  }
}
